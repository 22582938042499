import React from 'react';
import './Students.css';
import Paginator from '../paginator/Paginator'

class Students extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      page: 1,
      pageCount: 1
    };
    this.loadPage = this.loadPage.bind(this);
  }

  async loadPage(number) {
    const response = await fetch(`/users?page=${number}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': this.props.apiKey
      }
    });
    if (response.ok) {
      const result = await response.json();
      this.setState({
        students: result.data,
        page: result.meta.page_number,
        pageCount: result.meta.total_pages
      });
    } else if (response.status === 403) {
      this.props.onAccessDenied();
    } else {
      console.error('Server returned error', response.status);
    }
  }

  componentDidMount() {
    this.loadPage(1);
  }

  render() {
    return (
      <div className="Students">
        <h1>User List</h1>
        <table>
          {this.state.students.map((student, index) =>
          <tr className={index % 2 === 0 ? 'row-even' : 'row-odd'}>
            <td className="padding-column"></td>
            <td><i className="bi bi-check-circle-fill student-check"></i></td>
            <td className="student-name-column">
              <span className="student-nickname">{student.nickname}</span><br />
              <span className="student-full-name">{student.full_name}</span>
            </td>
            <td className="student-group-column">
              <span>...</span><br />
              <span>{student.group_name}</span>
            </td>
            <td className="padding-column"></td>
          </tr>
          )}
        </table>
        <Paginator page={this.state.page} pageCount={this.state.pageCount} loadPage={this.loadPage} />
      </div>
    );
  }
};

export default Students;