import React from 'react';
import Login from './login/Login';
import Personal from './personal/Personal';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: localStorage.getItem('apiKey')
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogin(apiKey, keep = false) {
    this.setState({apiKey});
    if (keep) {
      localStorage.setItem('apiKey', apiKey);
    }
  }

  handleLogout() {
    this.setState({apiKey: null});
    localStorage.removeItem('apiKey');
  }

  render() {
    return (
      <div className="App">
        {this.state.apiKey
          ? <Personal apiKey={this.state.apiKey} onLogout={this.handleLogout} />
          : <Login onLogin={this.handleLogin} />
        }
      </div>
    );
  }
};

export default App;
