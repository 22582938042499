import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import logo from './logo.svg';
import user from './user.svg';
import password from './password.svg';
import './Login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.form = {
      username: React.createRef(),
      password: React.createRef(),
      remember_me: React.createRef()
    };
  }

  async handleSubmit(event) {
    event.preventDefault();

    const keep = this.form.remember_me.current.checked;
    const data = {
      username: this.form.username.current.value,
      password: this.form.password.current.value
    };

    const response = await fetch("/auth", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    if (response.ok) {
      const result = await response.json();
      this.props.onLogin(result.data.apiKey, keep);
    } else if (response.status === 422) {
      const result = await response.json();
      console.log(result.error.validation_errors);
      this.setState({
        errors: result.error.validation_errors
      });
    } else {
      console.error('Server returned error', response.status);
    }
  }

  render() {
    return (
      <div className="Login">
        <img src={logo} className="Login-logo" alt="logo" /><br />
        <b>Welcome to the Learning Management System</b>
        <p>Please log in to continue</p>
        <Form className="d-grid gap-2" onSubmit={this.handleSubmit}>
          <Form.Group className="mb-3" controlId="username">
            <InputGroup>
              <img src={user} alt="username" className="Login-user-icon" />
              <Form.Control type="text" placeholder="Username" ref={this.form.username} className={('username' in this.state.errors) ? 'is-invalid' : ''} />
              {this.state.errors.username?.map((error) => <div class="invalid-feedback text-end">{error}</div>)}
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <InputGroup>
              <img src={password} alt="password" className="Login-user-icon" />
              <Form.Control type="password" placeholder="Password" ref={this.form.password} className={('password' in this.state.errors) ? 'is-invalid' : ''} />
              {this.state.errors.password?.map((error) => <div class="invalid-feedback text-end">{error}</div>)}
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="rememberMe">
            <Form.Check type="checkbox" label="Remember me" ref={this.form.remember_me} />
          </Form.Group>
          <Button variant="primary" type="submit" size="lg">
            Submit
          </Button>
        </Form>
      </div>
    );
  }
};

export default Login;