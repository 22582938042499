import './Paginator.css';

function Paginator(props) {
  return (
    <div className="pagination">
      {props.page === 1 ? '' :
      <>
        <button className="pagination-page-link" onClick={props.loadPage.bind(props, 1)}>&laquo;</button>
        <button className="pagination-page-link" onClick={props.loadPage.bind(props, props.page - 1)}>Previous</button>
      </>
      }
      {[...Array(props.pageCount).keys()].map(i => i + 1).map((number) =>
      <button className={'pagination-page-link' + (number === props.page ? ' active' : '')} onClick={props.loadPage.bind(props, number)}>{number}</button>
      )}
      {props.page === props.pageCount ? '' :
      <>
        <button className="pagination-page-link" onClick={props.loadPage.bind(props, props.page + 1)}>Next</button>
        <button className="pagination-page-link" onClick={props.loadPage.bind(props, props.pageCount)}>&raquo;</button>
      </>
      }
    </div>
  );
}

export default Paginator;