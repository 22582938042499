import React from 'react';
import Students from '../students/Students'
import './Personal.css';

class Personal extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  async handleLogout(event) {
    event.preventDefault();
    const response = await fetch("/auth", {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': this.props.apiKey
      }
    });
    if (!response.ok) {
      console.error('Server returned error', response.status);
    }
    this.props.onLogout();
  }

  render() {
    return (
      <div className="Personal">
        <Students apiKey={this.props.apiKey} onAccessDenied={this.props.onLogout} />
        <div className="footer">
          <button className="log-out" onClick={this.handleLogout}>Log Out</button>
        </div>
      </div>
    );
  }
};

export default Personal;